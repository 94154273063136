import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Paper, Box, Tabs, Tab, Card, CardActionArea, CardContent, CardMedia, Breadcrumbs, Link, Stack, Collapse } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import ShareIcon from '@mui/icons-material/Share';
import { useSelector, useDispatch } from 'react-redux';
import { handleGetEventDetail } from '../../store/thunk/events.js';
import ThemeVariation from '../../theme/theme-variation.js';
import Layout from '../layout/index.jsx';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Ticket from './Ticket';
import EventDetailSkeleton from './EventDetailSkeleton';
import { commonActions } from '../../store/slices/common.js';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const EventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openImage, setOpenImage] = useState({ female: false, male: false });

  const { data, fetching, error } = useSelector((state) => state.event.detail);

  useEffect(() => {
    if (id) {
      dispatch(handleGetEventDetail(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!fetching && !data?.result && !error) {
        navigate('/notfound');
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, [fetching, data, error, navigate]);

  const [tabValue, setTabValue] = useState(0);

  const event = data?.result ?? [];

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleCheckout = () => {
    navigate('/checkout/' + id);
  };

  const handleShare = async () => {
    const shareData = {
      title: event.title,
      text: event.description,
      url: window.location.href, // Current page URL
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error('Share failed', err);
      // Fallback: Copy the URL to clipboard
      navigator.clipboard.writeText(window.location.href).then(() => {
        dispatch(commonActions.showToast({ message: 'Link copied to clipboard!', type: 'info' }));
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleToggleImage = (type) => {
    setOpenImage((prevState) => ({
      ...prevState,
      [type]: !prevState[type], // Toggle untuk tipe yang dipilih
    }));
  };

  const THEME = ThemeVariation.Secondary;

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="#" onClick={() => navigate('/')}>
      HOME
    </Link>,
    <Link underline="hover" key="2" color="inherit">
      Event
    </Link>
  ];

  const backgroundImageUrl = require(`../../assets/${event?.event_banner === undefined ? 'product4.png' : event?.event_banner}`);
  let backgroundImageUrlMobile;
  try {
    backgroundImageUrlMobile = require(`../../assets/mobile-${event?.event_banner === undefined ? 'product4.png' : event?.event_banner}`);
  } catch (error) {
    backgroundImageUrlMobile = require(`../../assets/${event?.event_banner === undefined ? 'product4.png' : event?.event_banner}`);
  }
  return (
    <Layout 
      theme={THEME}
      showHeaderSpacing={false}
      color={event?.event_name !== undefined ?? event?.event_name.toUpperCase() === 'JAWARA RUN 2024' ? event?.color : ThemeVariation.Base}
    >
      <Container style={{ padding: '20px', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        {fetching && <EventDetailSkeleton />}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper
              sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${backgroundImageUrl})`,
                minHeight: '650px',
                borderRadius: '25px',
                '@media (max-width:500px)': { 
                  minHeight: '500px',
                  backgroundImage: `url(${backgroundImageUrlMobile})`,
                  backgroundSize: 'contain',
                }, '@media (min-width:501px) and (max-width:1024px)': {
                  minHeight: '500px', // Anda bisa menyesuaikan ukuran ini sesuai kebutuhan
                  backgroundImage: `url(${backgroundImageUrl})`,
                  backgroundSize: 'contain', // Anda bisa memilih 'contain' atau 'cover' sesuai kebutuhan
                },
              }}
            >
              {<img style={{ display: 'none' }} src={backgroundImageUrl} alt={event.event_name} />}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  backgroundColor: 'rgba(0,0,0,.2)',
                  borderRadius: '25px'
                }}
              />
              <Grid container>
                <Grid item md={6}>
                  <Box
                    sx={{
                      position: 'relative',
                      p: { xs: 3, md: 6 },
                      pr: { md: 0 },
                    }}
                  >
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <CardActionArea component="a" href="#">
              <Card sx={{ display: 'flex' }}>
                <CardContent sx={{ flex: 1 }}>
                  <Typography component="h2" variant="h5">
                    {event.event_name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {event.event_date}
                  </Typography>
                  <Typography variant="subtitle1" paragraph>
                    {event.venue}
                  </Typography>
                  <Button startIcon={<ShareIcon />} onClick={handleShare} style={{ marginLeft: '10px' }}>
                    Share
                  </Button>
                </CardContent>
                <CardMedia component="img" sx={{ width: 160, display: { xs: 'none', sm: 'block' } }} image={`https://dtiketin.com/static/media/${event?.event_logo}`} alt={event.event_name} />
              </Card>
            </CardActionArea>
            <Card style={{ marginTop: '20px' }}>
              <CardContent>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="event detail tabs" style={{ marginTop: '20px' }}>
                  <Tab label="Event Information" />
                  <Tab label="Waiver" />
                </Tabs>
                {tabValue === 0 && (
                  <Box sx={{ p: 3 }}>
                    <Markdown>{event.event_information}</Markdown>
                    {event.event_id === 3 && (
                      <>
                        {/* Card untuk Female Fit */}
                        <Card sx={{ marginTop: '20px', borderRadius: '12px'}}>
                          <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">Female Fit</Typography>
                            <Button
                              onClick={() => handleToggleImage('female')}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'auto',
                                padding: '6px 12px',
                              }}
                            >
                              {openImage.female ? (
                                <>
                                  <ExpandLess sx={{ marginRight: '8px' }} />
                                
                                </>
                              ) : (
                                <>
                                  <ExpandMore sx={{ marginRight: '8px' }} />
                                  
                                </>
                              )}
                            </Button>
                          </CardContent>

                          <Collapse in={openImage.female}>
                            <CardMedia
                              component="img"
                              src={require(`../../assets/aesifunrun/female.jpeg`)}
                              alt={event.event_name}
                              sx={{ width: '100%', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
                            />
                          </Collapse>
                        </Card>

                        {/* Card untuk Male Fit */}
                        <Card sx={{ marginTop: '20px', borderRadius: '12px'}}>
                          <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">Male Fit</Typography>
                            <Button
                              onClick={() => handleToggleImage('male')}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: 'auto',
                                padding: '6px 12px',
                              }}
                            >
                              {openImage.male ? (
                                <>
                                  <ExpandLess sx={{ marginRight: '8px' }} />
                                
                                </>
                              ) : (
                                <>
                                  <ExpandMore sx={{ marginRight: '8px' }} />
                                  
                                </>
                              )}
                            </Button>
                          </CardContent>

                          <Collapse in={openImage.male}>
                            <CardMedia
                              component="img"
                              src={require(`../../assets/aesifunrun/male.jpeg`)}
                              alt={event.event_name}
                              sx={{ width: '100%', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }}
                            />
                          </Collapse>
                        </Card>
                      </>
                    )}

                  </Box>
                )}
                {tabValue === 1 && (
                  <Box sx={{ p: 3 }}>
                    <Markdown>{event.event_waiver}</Markdown>
                  </Box>
                )}
                <Box sx={{ p: 3, mt: 3, borderTop: '1px solid #e0e0e0' }}>
                  <Typography variant="h6" gutterBottom>
                    Venue Map
                  </Typography>
                  <div style={{ width: '100%', height: '400px', overflow: 'hidden' }}>
                    <iframe
                      title="Google Map"
                      src={event.event_map}
                      width="100%"
                      height="100%"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                    ></iframe>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Ticket Prices
                  </Typography>
                  {event?.tickets !== undefined ? event?.tickets.map(ticket => (
                    <Ticket key={ticket.ticket_id} ticket={ticket} />
                  )) : null}       
                   <br />
                    <Typography variant="h5" component="div">
                      PROMOTER / EVENT ORGANIZER INFORMATION:
                    </Typography>
                    <Typography color="textSecondary">
                      {event.event_promotor}
                    </Typography>
                </CardContent>
              </Card>
              <br />
              <Button variant="contained" color="primary" onClick={handleCheckout} sx={{ borderRadius: '20px'}} className="checkout">
                Register Now
              </Button>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default EventDetail;
