import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FormParticipants from './FormParticipants'; // Ensure this component exists and is imported correctly
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const EventTickets = ({ ticket, form, name, dataTickets, index }) => {
  const { ticket_name, event, ticket_price, ticket_id, ticket_type} = ticket;
  const [year, month, day] = event.event_date.split("-");
  const formattedDate = `${day}-${month}-${year}`;
  const [open, setOpen] = useState(false);
  const [participants, setParticipants] = useState({});

  useEffect(() => {
    const initialTickets = dataTickets.reduce((acc, ticket) => {
      acc[ticket.name] = [];
      return acc;
    }, {});    
    setParticipants(initialTickets);
  }, [dataTickets]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setParticipants(form);
  };

  const handleConfirm = () => {
    setOpen(false);
    setParticipants(form);
  };

  return (
    <Card sx={{ display: 'flex', width: '100%', mb: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={{ flex: '1 0 auto', position: 'relative' }}>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={8}>
              <Typography component="div" variant="h6">
                {ticket_name}
              </Typography>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <ConfirmationNumberIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary" fontWeight="bold">
                    Rp. {ticket_price.toLocaleString('id-ID')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <EventIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {formattedDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <LocationOnIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {event.event_venue}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                  disabled={!(Boolean(dataTickets[index]?.ticket_availability) && dataTickets[index]?.ticket_quantity !== 0)}
                  // disabled={true}
                  sx={{ borderRadius: '20px', position: 'sticky', bottom: 0 }}
                >
                  Purchase Ticket
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} /> 
          <Typography component="div" variant="h6" sx={{ mt: 2 }}>
            Participants List
          </Typography>
          <List>
            {participants[name]?.map((participant, index) => {
              console.log( participant.jerseySizeKids, participant.jerseySize)
              let isParticipantIncomplete = 
                participant.fullName === '' ||
                participant.email === '' ||
                participant.birth === '' ||
                participant.gender === '' ||
                participant.nik === '' ||
                participant.phone === '' ||
                participant.nationality === '' ||
                participant.address === '' ||
                participant.bloodType === '' ||
                participant.emergencyContactName === '' ||
                participant.emergencyContactPhone === '' ||
                (ticket_type === 'family' 
                  ? (participant.jerseySize === '' && participant.jerseySizeKids === '')
                  : participant.jerseySize === '');
              return(
                <ListItem key={index}>
                  {
                    isParticipantIncomplete ?
                    <Typography variant="body1" style={{ color: 'red' }}>
                        Please complete participant data
                    </Typography> :
                    <ListItemText primary={`${index + 1}. ${participant.fullName}`}  secondary={participant.email} />
                  }
                </ListItem>
              )
            })}
          </List>
        </CardContent>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Participants</DialogTitle>
        <DialogContent>
          <FormParticipants name={name} ticketId={ticket_id} ticketType={ticket_type} values={form} indexTicket={index}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default EventTickets;
