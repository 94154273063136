import { checkoutActions } from '../slices/checkout'; // Adjust the import path as necessary
import { addCheckout, getChekout, getOrderId, getInvoice, getCountJawararun, getCountHeroes, getCountAesifunrun } from '../../services/checkout'; // Adjust the import path as necessary
import { commonActions } from '../slices/common';

export const handleCheckout = (param) => async (dispatch) => {
    try {
        dispatch(checkoutActions.addChekoutRequest());
        const resData = await addCheckout(param);
        const res = resData.data;
        dispatch(checkoutActions.addCheckoutSuccess());
        if(res.code === 0){
          window.location.href = res.transaction.redirect_url;
          dispatch(commonActions.showToast({ message: 'Checkout success', type: 'success' }));
        }else{
          dispatch(commonActions.showToast({ message: 'Checkout failed, please try again', type: 'error' }));
        }
        // dispatch(handleGetCheckout({}));
        return res;
    } catch (error) {
        dispatch(checkoutActions.addCheckoutFailed(error.message));
        dispatch(commonActions.showToast({ message: 'Checkout failed, please try again', type: 'error' }));
        throw error;
    }
};

export const handleGetCheckout = (param) => async (dispatch) => {
    try {
      dispatch(checkoutActions.getCheckoutRequest());
      const data = await getChekout(param);
      dispatch(checkoutActions.getCheckoutSuccess(data));
    } catch (error) {
      dispatch(checkoutActions.getCheckoutFailed(error.message));
    }
};

export const handleGetOrder = (orderId, transactionStatus) => async (dispatch) => {
  try {
    dispatch(checkoutActions.getOrderRequest());
    const response = await getOrderId(orderId, transactionStatus);
    dispatch(checkoutActions.getOrderSuccess(response.data));
  } catch (error) {
    dispatch(checkoutActions.getOrderFailed(error.message));
  }
};

export const handleGeInvoice = (orderId) => async (dispatch) => {
  try {
    dispatch(checkoutActions.getInvoiceRequest());
    const response = await getInvoice(orderId);
    dispatch(checkoutActions.getInvoiceSuccess(response.data));
  } catch (error) {
    dispatch(checkoutActions.getInvoiceFailed(error.message));
  }
};

export const handleGetJawararun = () => async (dispatch) => {
  try {
    dispatch(checkoutActions.getCountJawararunRequest());
    const response = await getCountJawararun();    
    dispatch(checkoutActions.getCountJawararunSuccess(response.data));
  } catch (error) {
    dispatch(checkoutActions.getCountJawararunFailed(error.message));
  }
};

export const handleGetHeroes = () => async (dispatch) => {
  try {
    dispatch(checkoutActions.getCountHeroesRequest());
    const response = await getCountHeroes();    
    dispatch(checkoutActions.getCountHeroesSuccess(response.data));
  } catch (error) {
    dispatch(checkoutActions.getCountHeroesFailed(error.message));
  }
};

export const handleGetAesfunrun = () => async (dispatch) => {
  try {
    dispatch(checkoutActions.getCountAesifunrunRequest());
    const response = await getCountAesifunrun();    
    dispatch(checkoutActions.getCountAesifunrunSuccess(response.data));
  } catch (error) {
    dispatch(checkoutActions.getCountAesifunrunFailed(error.message));
  }
};
