import { createSlice } from '@reduxjs/toolkit';

// Define your initial state
const initialState = {
  addChekout: {
    status: null,
    error: null,
  },
  list: {
    fetching: false,
    error: null,
    data: null,
  },
};

// Define request statuses
const RequestStatus = {
  Loading: 'loading',
  Success: 'success',
  Failed: 'failed',
};

// Reducer functions
const addChekoutRequest = (state) => {
  delete state.addChekout.error;
  state.addChekout.status = RequestStatus.Loading;
};

const addCheckoutSuccess = (state) => {
  state.addChekout.status = RequestStatus.Success;
};

const addCheckoutFailed = (state, { payload }) => {
  state.addChekout.error = payload;
  state.addChekout.status = RequestStatus.Failed;
};

const getCheckoutRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const getCheckoutSuccess = (state, { payload }) => {
  state.list.data = payload.data;
  state.list.fetching = false;
};

const getCheckoutFailed = (state, { payload }) => {
  state.list.error = payload;
  state.list.fetching = false;
};

const getOrderRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const getOrderSuccess = (state, action) => {
  state.list.data = action.payload;
  state.list.fetching = false;
};

const getOrderFailed = (state, action) => {
  state.list.error = action;
  state.list.fetching = false;
};

const getInvoiceRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const getInvoiceSuccess = (state, action) => {
  state.list.data = action.payload;
  state.list.fetching = false;
};

const getInvoiceFailed = (state, action) => {
  state.list.error = action;
  state.list.fetching = false;
};

const getCountJawararunRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const getCountJawararunSuccess = (state, action) => {
  state.list.data = action.payload;
  state.list.fetching = false;
};

const getCountJawararunFailed = (state, action) => {
  state.list.error = action;
  state.list.fetching = false;
};

const getCountHeroesRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const getCountHeroesSuccess = (state, action) => {
  state.list.data = action.payload;
  state.list.fetching = false;
};

const getCountHeroesFailed = (state, action) => {
  state.list.error = action;
  state.list.fetching = false;
};

const getCountAesifunrunRequest = (state) => {
  delete state.list.error;
  state.list.fetching = true;
};

const getCountAesifunrunSuccess = (state, action) => {
  state.list.data = action.payload;
  state.list.fetching = false;
};

const getCountAesifunrunFailed = (state, action) => {
  state.list.error = action;
  state.list.fetching = false;
};

// Create the slice
const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    addChekoutRequest,
    addCheckoutSuccess,
    addCheckoutFailed,

    getCheckoutRequest,
    getCheckoutSuccess,
    getCheckoutFailed,

    getOrderRequest,
    getOrderSuccess,
    getOrderFailed,

    getInvoiceRequest,
    getInvoiceSuccess,
    getInvoiceFailed,

    getCountJawararunRequest,
    getCountJawararunSuccess,
    getCountJawararunFailed,

    getCountHeroesRequest,
    getCountHeroesSuccess,
    getCountHeroesFailed,

    getCountAesifunrunRequest,
    getCountAesifunrunSuccess,
    getCountAesifunrunFailed
  },
});

// Export actions and reducer
export const checkoutActions = checkoutSlice.actions;
export const checkoutReducer = checkoutSlice.reducer;
